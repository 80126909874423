<template>
    <div class="container">
        <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :time-format="3" :token="token"/>
    </div>
</template>

<script>
  import Poster from '../../../components/Poster/Index'

  export default {
    name: "SixFreeOne",
    components: {
      Poster
    },
    data() {
      return {
        token: this.$route.query.token,
        posterList: [
          {
            backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220608110828834.png',
            tempBgImage: null,
            miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220608110856279.png',
            textColor: '#fff',
            textFontSize: 24,
            tempPoster: '',
            textPoint: [443, 1199],
            wxCode: {width: 187, point: [58, 1126]}
          },
          {
            backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220608110749211.png',
            tempBgImage: null,
            miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220608110909022.png',
            textColor: '#fff',
            tempPoster: '',
            textFontSize: 24,
            textPoint: [443, 1199],
            wxCode: {width: 187, point: [58, 1126]}
          },
        ],
        pagePath: this.baseHost + '/',
        moduleKey: ''
      }
    },
    created() {
      this.moduleKey = this.token ? '40e3c808-2243-4e9d-95a5-70ed5adfc34a' : 'gzhXMcx20220224'
    }
  }
</script>

<style scoped>

</style>